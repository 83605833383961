import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import theme from "../../Styles/theme.scss";

const SecondaryButtonStyled = styled(Button)(() => ({
  color: theme.primary6,
  textTransform: "none",
  textDecoration: "underline",
  "& .MuiButton-startIcon": { marginRight: 4 },
  "& .MuiButton-endIcon": { marginLeft: 4 },
  "&:disabled": {
    color: theme.neutral4,
    backgroundColor: theme.transparent,
  },
}));

const SecondaryButton = ({
  children,
  style,
  className,
  endIcon,
  startIcon,
  onClick,
  disabled,
}) => {
  return (
    <SecondaryButtonStyled
      className={className}
      style={style}
      endIcon={endIcon}
      startIcon={startIcon}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </SecondaryButtonStyled>
  );
};
export default SecondaryButton;
